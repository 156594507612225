/* Generated by Fontie - the magic webfont generator <http://fontie.flowyapps.com> */

@font-face {
    font-family: 'Nunito-Regular';
    src: url('Nunito-Regular.eot');
    src: url('Nunito-Regular.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Regular.woff2') format('woff2'),
    url('Nunito-Regular.woff') format('woff'),
    url('Nunito-Regular.ttf') format('truetype'),
    url('Nunito-Regular.otf') format('opentype'),
    url('Nunito-Regular.svg#Nunito-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family: 'Nunito-Italic';
    src: url('Nunito-Italic.eot');
    src: url('Nunito-Italic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Italic.woff2') format('woff2'),
    url('Nunito-Italic.woff') format('woff'),
    url('Nunito-Italic.ttf') format('truetype'),
    url('Nunito-Italic.otf') format('opentype'),
    url('Nunito-Italic.svg#Nunito-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Nunito-Light';
    src: url('Nunito-Light.eot');
    src: url('Nunito-Light.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Light.woff2') format('woff2'),
    url('Nunito-Light.woff') format('woff'),
    url('Nunito-Light.ttf') format('truetype'),
    url('Nunito-Light.otf') format('opentype'),
    url('Nunito-Light.svg#Nunito-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('Nunito-Bold.eot');
    src: url('Nunito-Bold.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Bold.woff2') format('woff2'),
    url('Nunito-Bold.woff') format('woff'),
    url('Nunito-Bold.ttf') format('truetype'),
    url('Nunito-Bold.otf') format('opentype'),
    url('Nunito-Bold.svg#Nunito-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Nunito-ExtraBold';
    src: url('Nunito-ExtraBold.eot');
    src: url('Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Nunito-ExtraBold.woff2') format('woff2'),
    url('Nunito-ExtraBold.woff') format('woff'),
    url('Nunito-ExtraBold.ttf') format('truetype'),
    url('Nunito-ExtraBold.otf') format('opentype'),
    url('Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family: 'MrsEavesRoman-Regular';
    src: url('MrsEavesRoman_gdi.eot');
    src: url('MrsEavesRoman_gdi.eot?#iefix') format('embedded-opentype'),
    url('MrsEavesRoman_gdi.woff') format('woff'),
    url('MrsEavesRoman_gdi.ttf') format('truetype'),
    url('MrsEavesRoman_gdi.svg#MrsEavesRoman-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'MrsEaves-Bold';
    src: url('MrsEaves-Bold_gdi.eot');
    src: url('MrsEaves-Bold_gdi.eot?#iefix') format('embedded-opentype'),
    url('MrsEaves-Bold_gdi.woff') format('woff'),
    url('MrsEaves-Bold_gdi.ttf') format('truetype'),
    url('MrsEaves-Bold_gdi.svg#MrsEaves-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'MrsEaves-Italic';
    src: url('MrsEaves-Italic.eot');
    src: url('MrsEaves-Italic.eot?#iefix') format('embedded-opentype'),
    url('MrsEaves-Italic.woff') format('woff'),
    url('MrsEaves-Italic.ttf') format('truetype'),
    url('MrsEaves-Italic.svg#MrsEaves-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'MrsEaves';
    src: url('MrsEaves.eot');
    src: url('MrsEaves.eot?#iefix') format('embedded-opentype'),
    url('MrsEaves.woff') format('woff'),
    url('MrsEaves.ttf') format('truetype'),
    url('MrsEaves.svg#MrsEaves') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'PT-Serif';
    src: url('PT-Serif.eot');
    src: url('PT-Serif.eot?#iefix') format('embedded-opentype'),
    url('PT-Serif.woff') format('woff'),
    url('PT-Serif.ttf') format('truetype'),
    url('PT-Serif.svg#PT-Serif') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'PT-Serif-Bold';
    src: url('PT-Serif-Bold.eot');
    src: url('PT-Serif-Bold.eot?#iefix') format('embedded-opentype'),
    url('PT-Serif-Bold.woff') format('woff'),
    url('PT-Serif-Bold.ttf') format('truetype'),
    url('PT-Serif-Bold.svg#PT-Serif-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'MrsEavesXLSerifNarOT-HeavyItalic';
    src: url('MrsEavesXLSerifNarOT-HeavyItalic.eot');
    src: url('MrsEavesXLSerifNarOT-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('MrsEavesXLSerifNarOT-HeavyItalic.woff') format('woff'),
    url('MrsEavesXLSerifNarOT-HeavyItalic.ttf') format('truetype'),
    url('MrsEavesXLSerifNarOT-HeavyItalic.svg#MrsEavesXLSerifNarOT-HeavyItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
